.footer {
    background-color: #1D1112;
    width: 100%;
    color: #FFFFFF;
    padding: 4rem 0; /* Converted padding from 64px to 4rem */
  }

  .footer a:hover{
    color: #F37636;
  }
  
  .topHalf {
    display: flex;
    justify-content: space-around;
  }
  
  .footerItem {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .form-wrapper {
    display: flex;
    flex-direction: column;
    gap: 0.75rem; /* Converted gap from 12px to 0.75rem */
    max-width: 25rem; /* Converted max-width from 400px to 25rem */
  }
  
  .footerHeader {
    font-size: 1.25rem; /* Converted font size to 1.25rem */
    font-weight: 600;
  }
  
  .input-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    max-width: 25rem; /* Converted max-width from 400px to 25rem */
    z-index: 0;
  }
  
  .input-wrapper input {
    padding: 1.25rem; /* Converted padding from 20px to 1.25rem */
    font-family: "DM Sans", sans-serif;
    font-size: 1rem;
    color: #2A2A2A;
    border-radius: 0.5rem; /* Converted border-radius from 8px to 0.5rem */
    border: 0;
    width: 100%;
  }
  
  input:focus {
    outline: none;
    border: 0.125rem solid #F37636; /* Converted border from 2px to 0.125rem */
  }
  
  .sendBtn {
    font-size: 1rem;
    font-family: "DM Sans", sans-serif;
    color: #FFFFFF;
    padding: 0.75rem 1.5rem; /* Converted padding values to rems */
    background-color: #F37636;
    border: 0;
    border-radius: 0.25rem;
    position: absolute;
    right: 0.5rem;
  }
  
  .links {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
  
  .links a {
    color: #FFFFFF;
  }
  
  .bottomHalf {
    padding: 0 4rem;
  }
  
  hr {
    border-color: #FFFFFF;
    margin-top: 8rem; /* Converted margin from 128px to 8rem */
    margin-bottom: 1rem; /* Converted margin from 16px to 1rem */
  }
  
  .belowLine {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .belowLine img {
    height: 2.5rem;
  }
  
  .socials {
    display: flex;
    gap: 2rem;
  }
  
  @media screen and (min-width: 700px) and (max-width: 1025px) {
    .topHalf {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 2.5rem;
      padding-left: 4rem;
      padding-right: 4rem;
    }
  
    .footerItem:nth-child(1) {
      grid-column-start: 1;
      grid-column-end: 4;
    }
  }
  
  @media screen and (max-width: 699px) {
    .topHalf {
      display: grid;
      gap: 2.25rem;
      grid-template-columns: repeat(1, 1fr);
      padding-left: 1.5rem;
      padding-right: 1.5rem;
    }
  
    .form-wrapper {
      width: 100%;
    }
  
    .form-wrapper p {
      max-width: 33rem; /* Converted max-width from 528px to 33rem */
    }
  
    .input-wrapper {
      max-width: 33rem; /* Converted max-width from 528px to 33rem */
    }
  
    .links {
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
  
    .links a {
      color: #FFFFFF;
    }
  
    .bottomHalf {
      padding: 0 1.5rem;
    }
  
    .belowLine {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }
  
    .belowLine p {
      flex-basis: 100%;
      order: 2;
      padding-top: 2rem;
      font-size: 0.75rem; /* Converted font size to 0.75rem */
      text-align: center;
    }
  
    .belowLine img {
      height: 2rem;
    }
  
    .socials {
      display: flex;
      gap: 10%;
    }
  
    .footer {
      padding: 2rem 0; /* Converted padding from 32px to 2rem */
    }
  }
  
