.architecture{
    background-color: #F6F6F6;
}

.architectureContainer{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 64px 0;
}

.architecture-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 3rem;
    width: 100%;
}

.arch-card-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}

.arch-card-wrapper .arch-card:first-child {
    padding: 0;
    height: 0;
  }

.arch-card{
    background-color: transparent;
    color: #707070;
    padding: 16px;
    max-width: 480px;
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    transition: background-color 0.3s ease, color 0.3s ease;
    cursor: pointer;
}

.card-current{
    background-color: #FFFFFF;
    cursor: default;
}

/* Styles for "subheader" and "content" within "card-current" */
.card-current .arch-sub-header {
    color: #F37636;
  }
  
  .card-current .content {
    color: #2A2A2A;
  }
  

.arch-sub-header{
    font-size: 1.25rem;
    font-weight: 600;
}

.arch-slide-wrap{
    max-width: 600px;
    height: 480px;
    position: relative;
}

.arch-image{
    width: 100%;
    height: 100%;
    border-radius: 0.5rem ;
    object-fit: cover;

}

.content{
    margin: 0;
}

.pagination-dots {
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .dot {
    width: 8px;
    height: 8px;
    background-color: #000000;
    opacity: 0.4;
    border-radius: 50%;
    margin-bottom: 8px;
    transition: transform 0.3s; 
  }
  
  .dot-current {
    width: 14px; 
    height: 14px;
    background-color: #F37636;
    opacity: 1;
  }
  


@media screen and (min-width: 541px) and (max-width: 1025px) {        
    .architecture-wrapper{
        flex-direction: column;
        gap: 1.5rem;
    }

    .arch-slide-wrap{
        width: 520px;
        order: -1;
        height: 424px;
    }       
}


@media screen and (max-width: 540px) {
    .architecture-wrapper{
        flex-direction: column;
        gap: 1rem;
    }

    .arch-slide-wrap{
        width: 90%;
        order: -1;
        height: 280px;
    }       
        
    .arch-card{
        width: 80%;
        gap: 0.5rem;
    }
    
    .arch-sub-header{
        font-size: 1.25rem;
    }
    

 }
