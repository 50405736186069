.communityContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 5.625rem 0; /* 90px to 5.625rem */
}

.commCardsContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
}

.commCard {
  display: flex;
  flex-direction: column;
  gap: 1.125rem; /* 18px to 1.125rem */
  padding: 1.5rem 1.25rem 1.25rem; /* 24px 20px 20px to 1.5rem 1.25rem 1.25rem */
  background-color: #FFFFFF;
  width: 18rem; /* 288px to 18rem */
  height: 15.125rem; /* 242px to 15.125rem */
  border-radius: 1rem; /* 16px to 1rem */
  box-shadow: 0px 0.25rem 1.5rem 0px rgba(0, 0, 0, 0.08); /* Updated box-shadow */
}

.cardImage {
  width: 4.5rem; /* 72px to 4.5rem */
  height: fit-content;
}

.cardTxt {
  display: flex;
  flex-direction: column;
  gap: 0.5625rem; /* 9px to 0.5625rem */
}

.cardHeader {
  color: #F37636;
  font-family: "DM Sans", sans-serif;
  font-size: 1.25rem; /* 20px to 1.25rem */
  font-weight: 700;
  line-height: 140%;
}

.cardContent {
  color: #2A2A2A;
}

.content {
  margin: 5.625rem 0; /* 90px to 5.625rem */
}

@media screen and (min-width: 700px) and (max-width: 1025px) {
  .commCardsContainer {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2rem;
  }

  .commCard {
    padding: 1.25rem; /* 20px to 1.25rem */
    width: 16.75rem; /* 268px to 16.75rem */
    height: 13.875rem; /* 222px to 13.875rem */
  }

  .cardImage {
    width: 4rem; /* 64px to 4rem */
    height: fit-content;
  }
}

@media screen and (max-width: 699px) {
  .commCardsContainer {
    grid-template-columns: repeat(1, 1fr);
    gap: 1.125rem; /* 18px to 1.125rem */
  }

  .commCard {
    gap: 0.5625rem; /* 9px to 0.5625rem */
    padding: 1.25rem; /* 20px to 1.25rem */
    width: 16.5rem; /* 264px to 16.5rem */
    height: 12.625rem; /* 202px to 12.625rem */
  }

  .cardImage {
    width: 3.625rem; /* 58px to 3.625rem */
  }
}
