.galleryContainer {
    background-color: #FFFFFF;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 4rem 0; /* Converted padding from 64px to 4rem */
  }
  
  .slide {
    width: 36.25rem; /* Converted width from 580px to 36.25rem */
    height: 27.5rem; /* Converted height from 440px to 27.5rem */
    border-radius: 0.5rem; /* Converted border-radius from 8px to 0.5rem */
    transition: transform 0.5s ease-in-out; /* Adjusted duration and easing as needed */
    object-fit: cover;
  }
  
  .current {
    width: 42.5rem; /* Converted width from 680px to 42.5rem */
    height: 31.25rem; /* Converted height from 500px to 31.25rem */
    border-radius: 0.5rem; /* Converted border-radius from 8px to 0.5rem */
    box-shadow: 0px 0.25rem 1.875rem 0px rgba(0, 0, 0, 0.08); /* Adjusted box-shadow values */
    object-fit: cover;
    cursor: pointer;
  }
  
  .image-wrapper {
    display: flex;
    align-items: center;
    gap: 0.75rem; /* Converted gap from 12px to 0.75rem */
    height: fit-content;
    padding: 0 1.25rem; /* Converted padding from 2rem to 1.25rem */
    white-space: nowrap;
  }
  
  .slider {
    overflow: hidden;
    width: 100%;
    z-index: 0;
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    height: fit-content;
  }
  
  .gallery-wrapper {
    width: 100%;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
    scroll-behavior: smooth;
    display: flex;
    flex-direction: row;
    align-items: center;
    white-space: nowrap;
  }
  
  .button-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    top: 50%;
    position: absolute;
  }
  
  .button-wrapper button {
    border: 0;
    background-color: #FFFFFF;
    border-radius: 50%;
    box-shadow: 0px 0.25rem 1.875rem rgba(0, 0, 0, 0.08); /* Adjusted box-shadow values */
    padding: 1rem; /* Converted padding from 16px to 1rem */
    margin: 0 1rem;
  }
  
  .button-wrapper button img {
    width: 1.25rem;
    height: 1.25rem;
  }
  
  .button-wrapper .previous:disabled {
    opacity: 0.5;
    box-shadow: 0;
    cursor: not-allowed;
  }
  
  .button-wrapper .next:disabled {
    opacity: 0.5;
    box-shadow: 0;
    cursor: not-allowed;
  }
  
  @media screen and (min-width: 700px) and (max-width: 1025px) {
    .image-wrapper {
      gap: 0.9375rem; /* Converted gap from 15px to 0.9375rem */
      padding: 0 0.9375rem; /* Converted padding from 15px to 0.9375rem */
    }
  
    .slide {
      width: 27.5625rem; /* Converted width from 441px to 27.5625rem */
      height: 23.3125rem; /* Converted height from 373px to 23.3125rem */
    }
  
    .current {
      width: 39.25rem; /* Converted width from 628px to 39.25rem */
      height: 27.3125rem; /* Converted height from 437px to 27.3125rem */
    }
  
    .button-wrapper {
      top: 44%;
    }
  }
  
  @media screen and (max-width: 699px) {
    .image-wrapper {
      gap: 0.3125rem; /* Converted gap from 5px to 0.3125rem */
      padding: 0 0.3125rem; /* Converted padding from 5px to 0.3125rem */
      align-items: center;
    }
  
    .slide {
      width: 17.75rem; /* Converted width from 284px to 17.75rem */
      height: 13.25rem; /* Converted height from 212px to 13.25rem */
    }
  
    .current {
      width: 21.75rem; /* Converted width from 348px to 21.75rem */
      height: 17.25rem; /* Converted height from 276px to 17.25rem */
    }
  
    .button-wrapper {
      top: 44%;
    }
  
    .button-wrapper button {
      margin: 0;
    }
  }
  
