.timer {
    padding: 4rem 0; /* Converted padding from 64px to 4rem */
    background-color: #F37636;
    color: #FFFFFF;
  }
  
  .timerContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .timesContainer {
    display: flex;
    gap: 1rem;
  }
  
  .timerContainer h2 {
    color: #FFFFFF;
  }
  
  .times {
    text-align: center;
    width: 13.75rem; /* Converted width from 220px to 13.75rem */
  }
  
  .times h1 {
    font-family: "DM Sans", sans-serif;
  }
  
  .times p {
    font-size: 1.25rem;
  }
  
  @media screen and (min-width: 700px) and (max-width: 1025px) {
    .times {
      width: 8rem; /* Converted width from 128px to 8rem */
    }
  
    .timesContainer {
      gap: 2rem;
    }
  }
  
  @media screen and (max-width: 699px) {
    .timer {
      padding: 2rem 0; /* Converted padding from 32px to 2rem */
    }
  
    .times {
      width: 4.375rem; /* Converted width from 80px to 5rem */
    }
  
    .times p {
      font-size: 0.875rem; /* Adjusted font size */
    }
  
    .timesContainer {
      gap: 0.5rem;
    }
  }
  
