.sidebar-overlay {
    background: rgba(0, 0, 0, 0.4);
    position: fixed;
    top: 0;
    z-index: 20;
    width: 100%;
    height: 100%;
  }
  
  .sidebar {
    height: 100%;
    background-color: #FFFFFF;
    position: absolute;
    top: 0;
    right: 0;
    width: 80%;
    padding-top: 1.25rem; /* Converted padding from 20px to 1.25rem */
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
  }
  
  .sidebar-top {
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 100%;
    gap: 1.5rem; /* Converted gap from 24px to 1.5rem */
    margin-bottom: 15%; /* Converted margin from 30% to 15% */
  }
  
  .close-sidebar {
    background-color: transparent;
    border: 0;
    padding-left: 1rem;
    margin: 0;
    height: fit-content;
    width: fit-content;
  }
  
  .menu-logo-wrap {
    width: fit-content;
    display: flex;
    align-items: center;
    height: fit-content;
    padding-left: 1rem;
  }
  
  .menu-logo {
    height: 2rem;
  }
  
  .btn-img {
    width: 1.75rem; /* Converted width from 28px to 1.75rem */
    height: fit-content;
  }
  
  .sidebar-links {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 1rem;
    
  }


  .menu-link-wrapper{
    width: 100%;
    box-sizing: border-box;
    padding: 0.5rem 0;
  }
  
  .menu-link-wrapper a {
    text-decoration: none;
    color: #2A2A2A;
    background-color: transparent;
    padding-top: 0.75rem; /* Converted padding from 12px to 0.75rem */
    padding-bottom: 0.75rem; /* Converted padding from 12px to 0.75rem */
    padding-left: 0.75rem; /* Converted padding from 12px to 0.75rem */
    padding-right: 15%; /* Converted padding from 240% to 15% */
    min-width: 100%;
    border-left: 0.25rem solid #FFFFFF; /* Converted border from 4px to 0.25rem */
  }
  
  .menu-link-wrapper:hover {
    background-color: #FEF1EB;
    border-left: 0.25rem solid #F37636; /* Converted border from 4px to 0.25rem */
  }
  
  /* @media screen and (min-width: 774px) {
    .sidebar-overlay {
      display: none;
    }
  } */
  
