.locationContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 4% 0; /* Converted padding from 64px to 4% */
  }
  
  .location-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4%; /* Converted gap from 4rem to 4% */
    width: 100%;
  }
  
  .map {
    width: 40%;
    height: 36rem; /* Converted height from 580px to 36rem */
    border: 1rem solid #F6F6F6; /* Converted border from 20px to 1rem */
    border-radius: 0.5rem;
  }
  
  .cards-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
  }
  
  .loc-card {
    background-color: #F6F6F6;
    padding: 1.25rem; /* Converted padding from 20px to 1.25rem */
    width: 30rem; /* Converted width from 480px to 30rem */
    border-radius: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
  }
  
  .loc-sub-header {
    font-size: 1.25rem;
    font-weight: 600;
    color: #121212;
  }
  
  .description {
    color: #555555;
  }
  
  @media screen and (min-width: 700px) and (max-width: 1025px) {
    .location-wrapper {
      flex-direction: column;
      gap: 4%;
    }
  
    .map {
      width: 30rem; /* Converted width from 480px to 30rem */
      height: 28rem; /* Converted height from 380px to 28rem */
      order: 2;
    }
  }
  
  @media screen and (max-width: 699px) {
    .location-wrapper {
      flex-direction: column;
      gap: 3rem;
    }
  
    .map {
      width: 80%;
      height: 17.6rem; /* Converted height from 264px to 17.6rem */
      order: 2;
    }
  
    .cards-container {
      gap: 1.25rem;
    }
  
    .loc-card {
      background-color: #F6F6F6;
      width: 80%;
      gap: 0.5rem;
    }
  
    .loc-sub-header {
      font-size: 1.25rem;
    }
  }
  
