.image-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #0F0F0F;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 4%;
    z-index: 1000; 
  }
  
  .image-modal {
    border-radius: 0;
    text-align: center;
  }
  
  .image-modal img {
    max-width: 100%;
    max-height: 80vh; 
    border-radius: 0 0 0 0;
    object-fit: contain; 
  }

  .close-btn-wrapper{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding-right: 4%;
    color: #FFFFFF;
    position: fixed;
    top: 4%;
  }
  
  
  .close-button {
    color: #FFFFFF;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 1rem;
    background-color: transparent;
    padding: 0;
    border: none;
    cursor: pointer;
  }

  .lightbox-content{
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .circleBtns{
    display: flex;
    justify-content: space-between;
    width: 100%;
    top: 50%;
    position: absolute;
  }

  
  .circleBtns button{
    background-color: transparent;
    border: 0;
    border-radius: 50%;
    margin: 0 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 3.5rem;
    height: 3.5rem;
  }

  .circleBtns button:hover{
    background-color: #3A3737;

  }


  .circleBtns button img{
    height: 2.5rem;
  }

  .lightbox-close{
    width: 1.5rem;
  }

  
  
  @media screen and (max-width: 699px) {
    .lightbox-close{
        width: 1.25rem;
      }

      .circleBtns button img{
        height: 2rem;
      }
    
      .circleBtns button{
        width: 3rem;
        height: 3rem;
      }
    
    
    
  }

  

  
  