.navbar {
  display: flex;
  justify-content: space-between;
  padding-right: 5%;
  padding-left: 5%;
  padding-top: 1.5rem; /* Converted padding from 24px to 1.5rem */
  padding-bottom: 1.5rem; /* Converted padding from 24px to 1.5rem */
  align-items: center;
  background-color: #FFFFFF;
  position: fixed;
  width: 100%;
  z-index: 10;
  box-sizing: border-box;
}

.logoDiv {
  width: fit-content;
  display: flex;
  align-items: center;
  height: fit-content;
}

.menuList {
  list-style: none;
  display: flex;
  gap: 3rem;
  margin: 0;
  padding-left: 0;
}

.menuLinks {
  display: flex;
  flex-direction: row;
  gap: 1.5rem;
  font-size: 1rem;
}

.button {
  padding: 1rem 2rem; /* Converted padding from 12px 24px to 1rem 2rem */
  text-align: center;
  background-color: transparent;
  border: 0.0625rem solid #F37636; /* Converted border from 1px to 0.0625rem */
  color: #F37636;
  border-radius: 0.5rem; /* Converted border-radius from 8px to 0.5rem */
  font-size: 1rem;
  font-family: "DM Sans", sans-serif;
  cursor: pointer;
}

.navLogo {
  height: 3rem;
}

.nav-link {
  color: #2A2A2A;
  position: relative;
  transition: background-color 0.3s;
  padding: 2.4rem 0; /* Converted padding from 2.25rem to 2.8125rem */
}

.nav-link::before {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 0;
  border-radius: 4rem; /* Converted border-radius from 64px to 4rem */
  background-color: #F37636;
}

.nav-link:hover::before {
  height: 0.25rem; /* Converted height from 4px to 0.25rem */
}

.nav-link:hover {
  color: #F37636;
}

.menu-btn {
  border: 0;
  background-color: transparent;
  display: none;
}

.menu-btn img {
  width: 2rem; /* Converted width from 32px to 2rem */
}

@media screen and (min-width: 768px) and (max-width: 1025px) {
  .nav-link {
    padding: 1.9rem 0; /* Converted padding from 2.25rem to 2.8125rem */
  }
  


  h1 {
    font-size: 5rem;
  }

  .navLogo {
    height: 2rem;
  }

  .menuList {
    gap: 1.25rem;
  }

  .menuLinks {
    gap: 0.75rem;
  }

  .button {
    padding: 1rem 2.5rem; /* Converted padding from 12px 20px to 1.5rem 2.5rem */
  }
}

@media screen and (max-width: 774px) {
  .navLogo {
    height: 2rem;
  }

  .navMenu {
    display: none;
  }

  .menu-btn {
    display: block;
  }

  .menu-btn img {
    width: 1.75rem; /* Converted width from 28px to 1.75rem */
  }

  .navbar {
    padding-top: 1.25rem; /* Converted padding from 20px to 1.25rem */
    padding-bottom: 1.25rem; /* Converted padding from 20px to 1.25rem */
  }
}
