@import url("https://fonts.googleapis.com/css2?family=DM+Sans:opsz,wght@9..40,400;9..40,500;9..40,600;9..40,700;9..40,900&family=Raleway:wght@400;500;600;700;800;900&display=swap");

body{
  margin: 0;
  box-sizing: border-box;
  
}

h1{
  margin: 0;
  font-family: "Raleway", sans-serif;
  font-size: 7.5rem;
  line-height: 140%;
  color: #FFFFFF;
}

p{
  margin: 0;
}

h2{
  margin: 0;
}

a{
  text-decoration: none;
  cursor: pointer;
}


.header{
  font-size: 2rem;
  font-family: "Raleway", sans-serif;
  margin-bottom: 2.5rem;
  line-height: 140%;
  color: #121212;
  text-align: center;
}

button{
  cursor: pointer;
}


.App {
  font-family: "DM Sans", sans-serif;
  font-size: 1rem;
  position: relative;
}

html {
  scroll-behavior: smooth; 
  transition: 0.8s;
  font-size: 16px;
}




@media screen and (min-width: 700px) and (max-width: 1025px) {        

  h1{
    font-size: 5rem;
  }
    
  }

  @media screen and (max-width: 699px) {
    h1{
      font-size: 2rem;
    }

    .header{
      font-size: 1.25rem;
      margin-bottom: 2rem;
    }
    
  
   }
