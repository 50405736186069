.heroContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url('../../assets/Herobgimage.png');
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  margin: 0;
  padding: 16% 0;
}

.heroContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2.5rem;
}

.heroContent button {
  background-color: #F37636;
  border: 0;
  color: #FFFFFF;
  padding: 1rem 2rem; /* Converted padding from 16px to 1rem */
}

.heroTxt {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
}

.heroTxt p {
  font-family: "Raleway", sans-serif;
  font-size: 1.25rem; /* Font size in rems */
  line-height: 140%;
  color: #FFFFFF;
}

.overlay {
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  position: fixed;
  top: 0;
  padding: 3.5rem 0; /* Converted padding from 3.5rem to 3.5rem */
  z-index: 20;
  width: 100%;
  height: 100%;
}

.hidden-overlay {
  display: none;
}

.form-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 2.5rem; /* Converted padding from 40px to 2.5rem */
  background-color: #FFFFFF;
  border-radius: 16px;
  width: 30rem; /* Converted width from 480px to 30rem */
  height: fit-content;
  justify-content: center;
}

.form-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.form-header p {
  color: #121212;
  font-family: Raleway;
  font-size: 1.25rem; /* Font size in rems */
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.form-header button {
  background-color: transparent;
  width: fit-content;
  height: fit-content;
  border-radius: 50%;
  border: 0;
  padding: 0;
}

.form-header button img {
  width: 1.5rem; /* Converted width from 24px to 1.5rem */
}

.form-container form {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  width: 100%;
}

.form-content {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  width: 100%;
}

.form-input {
  border: 1px solid #9D9D9D;
  background-color: transparent;
  padding: 1.25rem; /* Converted padding from 20px to 1.25rem */
  font-family: "DM Sans", sans-serif;
  font-size: 1rem; /* Font size in rems */
  color: #2A2A2A;
  border-radius: 8px;
}

.submit-btn {
  background-color: #F37636;
  border: 0;
  color: #FFFFFF;
  padding: 1rem 2rem; /* Converted padding from 16px to 1rem */
  text-align: center;
  border-radius: 8px;
  font-size: 1rem; /* Font size in rems */
  font-family: "DM Sans", sans-serif;
}

@media screen and (max-width: 540px) {
  .heroContainer {
    padding: 7.5rem 0; /* Converted padding from 120px to 7.5rem */
  }

  .heroTxt p {
    font-size: 1rem; /* Font size in rems */
  }

  .heroContent button {
    padding: 0.75rem 1.5rem; /* Converted padding from 12px to 0.75rem and 24px to 1.5rem */
  }

  .heroContent {
    gap: 1.5rem;
  }

  .heroTxt {
    gap: 0.375rem; /* Converted gap from 0.75rem to 0.375rem */
  }

  .overlay {
    padding: 0;
    align-items: flex-end;
  }

  .form-container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 2rem; /* Converted padding from 32px to 2rem */
    background-color: #FFFFFF;
    border-radius: 16px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    width: 100%;
  }

  .form-header p {
    font-size: 1.125rem; /* Font size in rems */
  }

  .form-content {
    display: flex;
    flex-direction: column;
    gap: 0.75rem; /* Converted gap from 12px to 0.75rem */
    width: 100%;
  }

  .form-content label {
    font-size: 0.875rem; /* Font size in rems */
    font-family: "DM Sans", sans-serif;
  }

  .form-input {
    padding: 1rem; /* Converted padding from 16px to 1rem */
  }

  .submit-btn {
    margin-bottom: 4%;
  }
}
